@import 'Styles/includes';

.Root {
    $root: &;

    width: 100%;
    height: 100%;

    &--Contain {
        object-fit: contain;
    }

    &--Cover {
        object-fit: cover;
    }
}
