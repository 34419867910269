@import 'Styles/includes';

.CardEditorial {
    $root: &;
    text-decoration: none;

    @include media(md) {
        display: flex;
        flex-direction: row;
        text-decoration: none;
    }

    &--Vertical {
        flex-direction: column;
        height: 100%;
    }

    &__Image {
        position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;

        @include media(md) {
            height: auto;
            width: calc(100% / 3);
        }

        #{$root}--Vertical & {
            height: 200px;
            width: 100%;
        }
    }

    &__Logo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 220px;
        max-height: 80%;
        margin: auto;
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 24px;

        @include media(md) {
            width: calc(100% / 3 * 2);
            padding-right: 80px;
        }

        #{$root}--Vertical & {
            flex-direction: column;
            margin: 24px 15px;

            @include media(md) {
                width: auto;
                padding-right: 0;
            }
        }
    }

    &__Title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.33;
        color: $text;
        margin-bottom: 16px;
    }

    &__Region {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        font-size: 1.1rem;
        line-height: 1.25;
        letter-spacing: 0.8px;
        color: $black;

        &:before {
            content: '';
            background-image: url('#{$publicpath}img/icon-location-black.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            display: inline-block;
            width: 12px;
            height: 14px;
            margin-right: 6px;
        }
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: $black;
        margin: 16px 0 28px;

        &::after {
            content: '';
            background-image: url('#{$publicpath}img/icon-arrow-right.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            display: inline-block;
            width: 14px;
            height: 9px;
            margin-left: 8px;
        }
    }

    &__Date {
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 0.8px;
        line-height: 1.5;
        color: $text;
        margin-top: auto;
        margin-bottom: 4px;
    }
}
