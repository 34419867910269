@import 'Styles/includes';

/**
*
* FilterSelect
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterSelect {
    width: 100%;
    display: inline-block;
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: left 16px center;

    @include media(md) {
        width: 250px;

        &--Small {
            width: 166px;
        }

        &--Location {
            width: 216px;
        }

        &--Category {
            width: 276px;
        }
    }

    &--FullWidth {
        width: 100%;
    }

    &--Location {
        background-image: url('#{$publicpath}img/icon-location-black.svg');
    }

    &--Category {
        background-image: url('#{$publicpath}img/icon-subject.svg');
        background-size: 14px;
    }

    &--HasIcon {
        :global {
            .Select__input {
                margin-left: 30px !important;
            }
        }
    }

    &--No-Icon {
        :global {
            .Select__single-value,
            .Select__input {
                padding-left: 16px !important;
            }
        }
    }

    &--Multi {
        :global {
            .Select__input {
                padding-left: 0px !important;
            }
        }
    }

    &--IncreaseHeight {
        :global {
            .Select__control {
                height: 52px !important;

                .Select__placeholder,
                .Select__single-value {
                    padding-left: 38px !important;
                }

                .Select__input {
                    margin-left: 38px !important;
                }
            }
        }
    }

    &--ArrangementSearch {
        width: 100%;

        @include media(md) {
            height: 56px;

            :global {
                .Select__control {
                    border: 1px solid $gray-40 !important;
                    background-position: right 24px center;
                    height: 56px !important;
                }

                .Select__input {
                    font-weight: normal;
                }
            }
        }
    }

    &__CustomOption {
        text-align: left;

        &:active,
        &:hover {
            background-color: $input-hover-bg !important;
            color: white !important;
        }

        &--Parent {
            font-weight: 700;
        }

        &--Location#{&}--Parent {
            position: sticky;
            top: -4px;
            border-bottom: 1px solid #eee;
            background-color: white;
        }

        &--Child {
            padding-left: 24px !important;
        }

        &:global {
            &.Select__option--is-selected {
                background: $input-select-bg !important;
                color: white !important;
            }
        }
    }
}
