@import 'Styles/includes';

/**
*
* TagList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.TagList {
    display: flex;
    flex-direction: column;

    &__Tag {
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 0.8px;
        border-radius: 2px;
        color: black;
        margin-bottom: 4px;
    }
}
