@import 'Styles/includes';

/**
*
* Title
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Title {
    $root: &;

    padding-bottom: 16px;
    margin-bottom: 24px;
    text-align: center;
    border-bottom: 1px solid $gray-20;

    @include media(md) {
        max-width: 75%;
        width: 75%;
        padding-bottom: 32px;
        margin: 0 auto 48px auto;
    }

    &--NoMargin {
        margin-bottom: 0;
    }

    &__Tag {
        font-size: 1.6rem;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 1.5;
        margin-bottom: 8px;
        letter-spacing: 0.4px;

        @include media(lg) {
            font-size: 1.8rem;
            margin-bottom: 8px;
        }
    }

    &__Title {
        font-weight: bold;
        line-height: 1.33;
        font-size: 2.4rem;

        #{$root}--HasChildren & {
            margin-bottom: 24px;
        }

        @include media(lg) {
            font-size: 3.6rem;
        }
    }

}
