@import 'Styles/includes';

/**
*
* ButtonReadMore
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ButtonReadMore {
    font-size: 1.8rem;
    letter-spacing: 0.4px;
    color: $black;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    border: none;
    background: transparent;
    cursor: pointer;
    position: relative;
    font-family: proxima-nova, Arial;

    @include media(md) {
        font-size: 2rem;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        bottom: -24px;
        left: calc(50% - 8px);
        background-image: url('#{$publicpath}img/icon-arrow.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }
}
